import React, { useContext, useEffect, useState } from "react";
import "./index.css";
import NFTProjectTile from "../../components/NFTProjectTile";
import styled from "styled-components";
import { localized } from "../../Standard/utils/localized";
import texts from "./localization";
import LocaleContext from "../../Standard/LocaleContext";
import GradientCircles from "../../Standard/decorations/GradientCircles";
import NFTMarketplaceSubHeader from "../../components/SubHeader/variants/NftMarketplace";
import { instance } from "../../api";
import { Project } from "../../types";

const Wrapper = styled.div`
  margin-top: 56px;
  max-width: 1500px;
  z-index: 1;

  padding-left: 80px;
  padding-right: 80px;
  @media screen and (max-width: 900px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
  z-index: 1;
`;

const ProjectFlex = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  gap: 40px;
  justify-content: center;
  flex-wrap: wrap;

  @media screen and (max-width: 900px) {
    justify-content: center !important;
  }
`;

const Main = () => {
  const { locale } = useContext(LocaleContext);
  const [projects, setProjects] = useState<Project[] | undefined>(undefined);

  const getProjects = async () => {
    try {
      const response = await instance.get("/projects/");
      setProjects(response.data.data.projects);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <>
      <Container>
        <NFTMarketplaceSubHeader subtitle={localized(texts.subtitle, locale)} />
        <Wrapper>
          <ProjectFlex>
            {projects ?
              <>
                {projects.filter(project => project.uuid !== '296120ee-9555-4f1d-a5d9-6dc435eb4384').map(project => (
                  <NFTProjectTile key={project.uuid} project={project} />
                ))}
              </>
              :
              <>
                {/* @ts-ignore */}
                <NFTProjectTile project={undefined} />
                {/* @ts-ignore */}
                <NFTProjectTile project={undefined} />
                {/* @ts-ignore */}
                <NFTProjectTile project={undefined} />
                {/* @ts-ignore */}
                <NFTProjectTile project={undefined} />
                {/* @ts-ignore */}
                <NFTProjectTile project={undefined} />
              </>
            }
          </ProjectFlex>
        </Wrapper>
      </Container>
      <GradientCircles />
    </>
  );
};

export default Main;

